import request from '@/utils/ucenterrequest'

// 验证手机号
export function checkMobileApi(params) {
  return request({
    url: '/Activity/Activity/checkMobile',
    method: 'GET',
    params
  })
}

// 签到成功
export function signinsuccessApi(params) {
  return request({
    url: '/activity/activity/signinSuccess',
    method: 'GET',
    params
  })
}
// 在微信小程序里的授权登录
export function loginWechatUser(params) {
  return request({
    url: '/api/miniprogram/getUserInfo',
    method: 'POST',
    params
  })
}
