<template>
  <div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import WechatState from '@/utils/isWechatState.js'
import { loginWechatUser } from '@/api/signin.js'
import wxsdk from 'weixin-js-sdk'
export default {
  data() {
    return {
      toPath: '',
      isMiniprogramEnv: false
    }
  },
  created() {
    // 检验小程序环境
    this.delayCheckState()
  },
  methods: {
    // 检验小程序环境
    delayCheckState() {
      console.log(WechatState.isWechat, '------WechatState.isWechat-----进来了')
      if (WechatState.isWechat) {
      // 微信小程序
        const url = window.location.href
        console.log(url, 'url')
        const token = this.getQueryString(url, 'token')
        const uuid = this.getQueryString(url, 'uuid')
        const app_code = this.getQueryString(url, 'app_code')
        const wxopenid = this.getQueryString(url, 'wxopenid')
        const uid = Cookie.get('uid')
        const toUrl = this.getQueryString(url, 'toUrl')
        if (token && uuid && !uid) {
          Cookie.set('access_token', token, { expires: 365 })
          Cookie.set('app_code', app_code, { expires: 365 })
          Cookie.set('uuid', uuid, { expires: 365 })
          Cookie.set('wxopenid', wxopenid, { expires: 365 })
          // this.$router.push({ path: '/mwxLog', query: { token: token, uuid: uuid, app_code: app_code }})
          const info = {
            app_code: app_code,
            access_token: token,
            uuid: uuid
          }
          console.log(info, '进来之后的接口参数')
          loginWechatUser(info).then((res) => {
            console.log(res, 'res')
            const errNo = res.errNo
            if (errNo === 0) {
              const result = res.result
              const username = result.username
              console.log('登录成功 ', username)
              console.log(decodeURIComponent(toUrl), '----------ssssssssssss')
              window.location.href = decodeURIComponent(toUrl)
            }
          // this.$router.go(-1)
          }).catch((err) => {
          // this.$router.go(-1)
          })
        }
      }
    },
    // 获取地址栏参数
    getQueryString(url, param) {
    // param为要获取的参数名 注:获取不到是为null
      var arr = url.split('?') // 分割域名和参数界限
      if (arr.length > 1) {
        arr = arr[1].split('&') // 分割参数
        for (var i = 0; i < arr.length; i++) {
          var tem = arr[i].split('=') // 分割参数名和参数内容
          if (tem[0] === param) {
            return tem[1]
          }
        }
        return null
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="scss">

</style>
